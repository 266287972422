import styles from './Shift.module.css';

import { LocalDate, LocalTime } from '../LocalDateTime/LocalDateTime.js';

const Action = ({ assigned, available, handleShiftSelection }) => {
    return (
        !assigned && available ? (
            <span><i onClick={ handleShiftSelection } className='fa-solid fa-thumbs-up fa-xs'></i></span>
        ) : !available ? (
            <span><i className='fa-solid fa-ban fa-xs'></i></span>
        ) : (
            <span><i className='fa-solid fa-thumbs-down fa-xs'></i></span>
        )
    );
};

export const Shift = ({ shift, assigned, nextDuty, onShiftSelection }) => {
    const time = new Date().getTime();

    const handleShiftSelection = () => {
        onShiftSelection(shift);
    };

    return (
        <div className={ `${ styles.shift } ${ !nextDuty && styles[ shift.name ] } ${ (!shift.available || time > shift.end) && styles.disabled } ${ assigned && styles.assigned }` }>
            <span>{ shift.name }</span>
            <LocalDate date={ shift.start } />
            <LocalTime time={ shift.start } />
            <LocalTime time={ shift.end } />
            { !nextDuty && <Action assigned={ assigned } available={ shift.available } onShiftSelection={ handleShiftSelection } /> }
        </div>
    );
};
import styles from './Duty.module.css';

import { toggleDuty } from '../../api.js';
import { useState, useEffect, use } from "react";
import { LocalDate, LocalTime } from '../LocalDateTime/LocalDateTime.js';
import { Patroller } from '../Patroller/Patroller.js';
import { Radio } from '../Radio/Radio.js';
import { Shift } from '../Shift/Shift.js';

export const Duty = ({ duties, onOffDuty }) => {
    const [ nextDuty, setNextDuty ] = useState({ patroller: {}, radio: {}, shift: {} });
    
    const today = new Date(new Date().toUTCString()).toISOString().split('T')[0];

    const handleOnDuty = async () => {
        const toggle = await toggleDuty(nextDuty.duty.id);

        onOffDuty(toggle);
    };

    useEffect(() => {
        const filtered = duties.filter(d => d.shift.date >= today);

        setNextDuty(filtered.sort((a, b) => a.shift.start - b.shift.start).shift());
    }, [ duties ]);

    return (
        <>
            <div className={ styles.header }>
                <span>Assigned Shifts:<span>{ duties.length }</span></span>
                <span></span>
            </div>

            <div className={ styles.duty }>
                {
                    duties.sort((a, b) => a.shift.start - b.shift.start).map(d => (
                        d.shift.date === today && ( 
                            <Shift key={ d.shift.id } shift={ d.shift } nextDuty={ nextDuty } />
                        )
                    ))
                }

                {                        
                    nextDuty && nextDuty.shift.date === today ? (
                        <button onClick={ handleOnDuty }>{ onOffDuty ? 'start shift' : 'end shift' }</button>
                    ) : nextDuty ? (
                        <p>Next shift: <LocalDate date={ nextDuty.shift.start } /> at <LocalTime time={ nextDuty.shift.start } /></p>
                    ) : (
                        <p>No upcoming shifts</p>
                    )
                }
            </div>
        </>
    );
};
import styles from './Patroller.module.css';

export const Patroller = ({ patroller, editable, onChange, onLogOut }) => {
    return (
        <>
            <div className={ styles.header }>
                <span>Patroller Info</span>
                <span className={ styles.logout } onClick={ onLogOut }>Logout</span>
            </div>
            
            <div className={ styles.patroller }>
                { editable ? <input type="number" id="patrollerNspNumber" autoComplete='off' placeholder='NSP Number' value={ patroller.nspNumber } onChange={ onChange } /> : <div className={ styles.info }><span>NSP Number</span><span>{ patroller.nspNumber }</span></div> }
                { editable ? <input type="text" id="patrollerFirstName" autoComplete='off' placeholder='First Name' value={ patroller.firstName } onChange={ onChange } /> : <div className={ styles.info }><span>First Name</span><span>{ patroller.firstName }</span></div> }
                { editable ? <input type="text" id="patrollerLastName" autoComplete='off' placeholder='Last Name' value={ patroller.lastName } onChange={ onChange } /> : <div className={ styles.info }><span>Last Name</span><span>{ patroller.lastName }</span></div> }
                { editable && patroller.phone ? <input type="text" id="patrollerPhone" autoComplete='tel' placeholder='Phone' value={ patroller.phone } onChange={ onChange } /> : <div className={ styles.info }><span>Phone</span><span>{ patroller.phone }</span></div> }
                { editable && patroller.email ? <input type="text" id="patrollerEmail" autoComplete='email' placeholder='Email' value={ patroller.email } onChange={ onChange } /> : <div className={ styles.info }><span>Email</span><span>{ patroller.email }</span></div> }
            </div>
        </>
    );
};
import styles from './Shifts.module.css';

import { Shift } from '../Shift/Shift.js';
import { useState, useEffect } from 'react';

export const Shifts = ({ shifts, duties, size = 5, onShiftSelection }) => {
    const [ page, setPage ] = useState(1);
    const [ pageSize, setPageSize ] = useState(Math.min(size, 7) - 1);
    const [ filtered, setFiltered ] = useState([]);

    const getPage = (p) => {
        const page = shifts.filter(s => {
            const pageDate = new Date();
            const shiftDate = new Date(s.start);

            pageDate.setDate(pageDate.getDate() + (p * pageSize));

            if (p < 0) {
                return shiftDate >= pageDate && shiftDate < new Date(pageDate.getTime() + (pageSize * 24 * 60 * 60 * 1000));
            } else {
                return shiftDate <= pageDate && shiftDate > new Date(pageDate.getTime() - (pageSize * 24 * 60 * 60 * 1000));
            }
        });
        
        return page;
    };

    // show previous/next page of shifts
    const handlePageClick = (direction) => () => {
        const newPage = page + direction;
        const newNewPage = newPage ? newPage : newPage + direction;

        if (getPage(newNewPage).length === 0) {
            return;
        } else {
            setPage(newNewPage);
        }
    };

    useEffect(() => {
        setPageSize(Math.min(size, 7) - 1);
    }, [ ]);

    useEffect(() => {
        setFiltered(getPage(page));
    }, [ shifts, page ]);

    return (
        <>
            <div className={ styles.header }>
                <span>Available Shifts</span>
                <div className={ styles.page }>
                    { getPage(page - 1).length > 0 ? ( <span><i onClick={ handlePageClick(-1) } className='fa-solid fa-angles-left fa-sm'></i></span> ) : ( <span>&nbsp;</span> ) }
                    <span>&nbsp;{ page }&nbsp;</span>
                    { getPage(page + 1).length > 0 ? ( <span><i onClick={ handlePageClick(1) } className='fa-solid fa-angles-right fa-sm'></i></span> ) : ( <span>&nbsp;</span> ) }
                </div>
            </div>

            <div className={ styles.shifts }>
                <div className={ styles.info }>
                    <span>Shift</span>
                    <span>Date</span>
                    <span>Start</span>
                    <span>End</span>
                    <span>Add</span>
                </div>
                
                { filtered.map(f => (
                    <Shift key={ f.id } shift={ f } assigned={ duties.find(d => d.shift.id === f.id) } onShiftSelection={ onShiftSelection }/>
                )) }
            </div>
        </>
    );
};
import styles from './LocalDateTime.module.css';

const formatTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        ampm: true,
        hour12: true
    }).replace(' AM', 'a').replace(' PM', 'p');
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit'
    }).replace('-', ' ');
};

export const LocalDate = ({ date }) => {
    return (
        <span>{ formatDate(date) }</span>
    );
};

export const LocalTime = ({ time }) => {
    return (
        <span>{ formatTime(time) }</span>
    );
};
import styles from './LogInWrapper.module.css';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase.js";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

export const LogInWrapper = () => {
    const [ hasError, setHasError ] = useState(false);

    const handleRegister = () => {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
    
        createUserWithEmailAndPassword(auth, email, password)
            .then((credential) => {
                // send email verification
                sendEmailVerification(credential.user, { url: 'https://krusty-boi.com' });

                setHasError(false);
            })
            .catch((error) => {
                setHasError(true);
            });
    };

    const handleSignIn = async () => {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
    
        await signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setHasError(false);
            })
            .catch(async (error) => {
                setHasError(true);
            });
    }

    return (
        <>
            {
                !auth.currentUser ?       
                    <div className="main-content">
                        <h1>Patrol Manager Log In</h1>
                        
                        <div className="login-box">
                            <div id="loginInputs">
                                <input type="email" id="email" placeholder="Email" autoComplete="email" />
                                <input type="password" id="password" placeholder="Password" autoComplete="current-password" />
                                { hasError && <div id="loginError" className="error-message">invalid email/password or already registered</div> }
                            </div>

                            <div id="registerSignIn">
                                <ul id="passwordRequirements">
                                    <li id="length">at least 8 characters</li>
                                    <li id="lowercase">1 lowercase letter</li>
                                    <li id="uppercase">1 uppercase letter</li>
                                    <li id="number">1 number</li>
                                    <li id="special">1 special character</li>
                                </ul>
                            
                                <div id="buttons">
                                    <button id="registerButton" onClick={ handleRegister }>register</button>
                                    <button id="signInButton" onClick={ handleSignIn }>sign in</button>
                                </div>
                            </div>
                        </div>

                        <div id="help">
                            <Link to="/help">Need help?</Link>
                        </div>
                    </div>
                : <Navigate to="/dashboard" />
            }
        </>
    );
};
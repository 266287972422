import { auth } from "./firebase.js";

export const getDuties = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/duties/${ id }`, payload);
        const json = await response.json();

        return json;
    } catch (error) {
        console.error('getDuties:', error);

        return error;
    }
};

export const toggleDuty = async (id) => {
    try {
        const payload = {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/duties/${ id }`, payload);
        const json = await response.json();

        return json;
    } catch (error) {
        console.error('actionDuty:', error);

        return error;
    }
};

export const getPatroller = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/patrollers/${ id }`, payload);
        
        if (response.ok) {
            const json = await response.json();
            
            return json;
        } else {
            return null;
        }
    } catch (error) {
        console.error('getPatroller:', error);

        return error;
    }
};

export const getShift = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/shifts/${ id }`, payload);
        
        if (response.ok) {
            const json = await response.json();
            
            return json;
        } else {
            return null;
        }
    } catch (error) {
        console.error('getShift:', error);

        return error;
    }
};

export const getShifts = async () => {
    try {
        const payload = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/schedule/shifts`, payload);
        
        if (response.ok) {
            const json = await response.json();

            return json;
        } else {
            return null;
        }
    } catch (error) {
        console.error('getShifts:', error);

        return error;
    }
};

export const setDutyAssignment = async (duty) => {
    try {
        const payload = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${ await auth.currentUser.getIdToken(true) }`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(duty)
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/duties`, payload);

        return response.ok;
    } catch (error) {
        console.error('setDutyAssignment:', error);

        return error;
    }
};

export const getRadio = async (id) => {
    try {
        const payload = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch(`https://krusty-boi.com/api/v1/manage/radios/${ id }`, payload);
        
        if (response.ok) {
            const json = await response.json();
            
            return json;
        } else {
            return null;
        }
    } catch (error) {
        console.error('getRadio:', error);

        return error;
    }
};
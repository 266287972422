import styles from './App.module.css';

import { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ProtectedRoutes } from './ProtectedRoutes';
import { auth } from "./firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { LogInWrapper } from "./components/LogInWrapper/LogInWrapper";
import { Dashboard } from "./components/Dashboard/Dashboard";

const Header = () => {
    return (
        <header className={ styles.header }>
            <h1>Patrol Manager</h1>
        </header>
    );
};

const Footer = () => {
    return (
        <footer className={ styles.footer }>
            <p>&copy; 2024 | Patrol360</p>
        </footer>
    );
};

const Init = () => {
    const [ isVerified, setIsVerified ] = useState(false);

    // setup authentication observer
    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user && auth.currentUser && auth.currentUser.emailVerified) {
                setIsVerified(true);
            } else {
                setIsVerified(false);
            }
        });

        return unsub;
    }, [ ]);

    return (
        <>
            <Routes>
                <Route path="/" element={ <LogInWrapper /> } />
                <Route element={<ProtectedRoutes />}>
                    <Route path="/dashboard" element={ <Dashboard /> } />
                </Route>
                <Route path="*" element={ <LogInWrapper /> } />
            </Routes>
        </>
    );
};

export default function App() {
    return (
        <>
            <Header />

            <BrowserRouter
                future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                }
                }>
                <Init />
            </BrowserRouter>

            <Footer />
        </>
    );
};
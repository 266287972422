import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBK7Yy1QZQ-YVyW7JMzr-BBWkgINoRlcq4",
  authDomain: "patrol-manager-bb3bb.firebaseapp.com",
  databaseURL: "https://patrol-manager-bb3bb-default-rtdb.firebaseio.com",
  projectId: "patrol-manager-bb3bb",
  storageBucket: "patrol-manager-bb3bb.firebasestorage.app",
  messagingSenderId: "628747641383",
  appId: "1:628747641383:web:59353a4e795d5754fce402",
  measurementId: "G-FGY6ZWGZFK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// initialize authentication
export const auth = getAuth(app);

// Query the database for random questions from selected chapters
export const firebaseRef = ref(getDatabase(app));
import styles from './Dashboard.module.css';

import { auth } from "../../firebase.js";
import { getPatroller, getDuties, getShifts, getRadio, setDutyAssignment } from "../../api.js";
import { useState, useEffect  } from "react";
import { Patroller } from '../Patroller/Patroller.js';
import { Duty } from '../Duty/Duty.js';
import { Shifts } from '../Shifts/Shifts.js';
import { Radio } from '../Radio/Radio.js';

export const Dashboard = () => {
    const [ patroller, setPatroller ] = useState({ nspNumber: '', firstName: '', lastName: '' });
    const [ shifts, setShifts ] = useState([]);
    const [ duties, setDuties ] = useState([]);
    const [ radio, setRadio ] = useState({ number: '' });
    const [ offDuty, setOffDuty ] = useState(false);

    const handleDutyAssignment = async (shift) => {
        const duty = { patroller, shift, radio };
        const assigned = await setDutyAssignment(duty);

        if (assigned) {
            setDuties(await getDuties(auth.currentUser.uid));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const patrollerData = await getPatroller(auth.currentUser.uid);
            const shiftsData = await getShifts();
            const dutiesData = await getDuties(auth.currentUser.uid);
            const radioData = await getRadio(auth.currentUser.uid);

            setPatroller(patrollerData);
            setShifts(shiftsData);
            setDuties(dutiesData);
            setRadio(radioData);
        };

        fetchData();
    }, []);
    
    return (
        <div className={ styles.dashboard }>
            <Patroller patroller={ patroller } editable={ false } onChange={ null } />
            
            <Duty duties={ duties } onOffDuty={ setOffDuty } />

            <Shifts shifts={ shifts } duties={ duties } onShiftSelection={ handleDutyAssignment } />

            <Radio radio={ radio } editable={ false } />
        </div>
    );
};
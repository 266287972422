import styles from './Radio.module.css';

export const Radio = ({ radio, editable }) => {
    const handleOnChange = () => {

    };

    const handleAdd = () => {
            
    };
    
    return (
        <>
            <div className={ styles.header }>
                <span>Radios</span>
                <span className={ styles.add } onClick={ handleAdd }>Add</span>
            </div>
            
            <div className={ styles.radio }>
                { editable && radio ? (
                    <input type="number" id='radioNumber' autoComplete='off' placeholder='Radio Number' value={ radio.number } onChange={ handleOnChange } />
                ) : (
                    <div className={ styles.info }><span>No saved radios</span></div>
                )}
            </div>
        
        </>
    );
};